<template>
    <div class="container padding-container">
        <el-button class="topBtns" type="primary" icon="el-icon-plus" @click="routerChange(3)">新增活动</el-button>
        <div class="border-container">
            <el-row class="search-container" type="flex" align="middle" justify="space-between">
                <el-col :lg="2" :sm="4" :xs="8">
                    <span class="list-title">活动列表</span>
                </el-col>
                <!-- <el-col :lg="22" :sm="20" :xs="16" class="search-area">
                    <el-col :span='6' class="search-item">
                        <el-input class="value" v-model="searchForm.name" placeholder="搜索用户"></el-input>
                    </el-col>
                </el-col> -->
            </el-row>
            <div class="table-container">
                <el-table :data="tableData" tooltip-effect="dark" style="width: 100%" size="medium"
                    :header-cell-style="{height: '40px', background: 'linear-gradient(180deg,rgba(251,252,253,1) 0%,rgba(246,248,250,1) 100%)'}"
                    :cell-style="{height: '50px',padding: '5px 0'}" header-align="center" :row-key="(row) => row.id">
                    <el-table-column prop="name" label="活动名称" min-width="200" :show-overflow-tooltip="true">
                        <template slot-scope="scope">
                            <div class="hover-text-colourful fz-bold" @click="routerChange(1, scope.row)"
                                style="padding-left: 5px">
                                {{scope.row.name || '-'}}
                            </div>
                        </template>
                    </el-table-column>

                    <el-table-column label="名额数量" min-width="100" :show-overflow-tooltip="true" align="center">
                        <template slot-scope="scope" class="text-colourful">
                            {{scope.row.count || '-'}}
                        </template>
                    </el-table-column>
                    <el-table-column label="访问数" min-width="120" align="center">
                        <template slot-scope="scope">
                            {{scope.row.used_count || '-'}}
                        </template>
                    </el-table-column>
                    <el-table-column label="创建时间" min-width="155" align="center">
                        <template slot-scope="scope">
                            {{scope.row.create_time | timeFilter}}
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" min-width="150" align="center">
                        <template slot-scope="scope">
                            <el-button type="text" class="icon-btn" @click="routerChange(1, scope.row)">
                                <el-tooltip placement="top" content="详情">
                                    <i class="iconfont icon-check" />
                                </el-tooltip>
                            </el-button>
                            <el-button type="text" class="icon-btn" @click="handleQRCodeShow(scope.row)">
                                <el-tooltip placement="top" content="二维码">
                                    <i class="iconfont icon-qrcode" />
                                </el-tooltip>
                            </el-button>
                        </template>
                    </el-table-column>
                    <div class="" slot="empty">
                        <no-data></no-data>
                    </div>
                </el-table>
            </div>
            <div class="page-container">
                <el-pagination layout="total, sizes, prev, pager, next" background @size-change="pageSizeChange"
                    @current-change="pageChange" :current-page="currentPage" :page-sizes="[total, 10, 15, 20, 25]"
                    :total="total" :page-size="pageSize">
                </el-pagination>
            </div>
        </div>
        <el-dialog title="分享活动" :visible.sync="qrcodeVisible" @close="handleQRCodeClose" class="activity-share-dialog"  width="500px">
            <div class="dialog-content">
                <div class="form-item channel">
                    <div class="key">渠道</div>
                    <el-select class="value" v-model="channel_ids" placeholder="请选择" @change="handleChannelChange">
                        <el-option v-for="item in channelList" :key="item.id" :label="item.name" :value="item.id"/>
                    </el-select>
                </div>
                <div class="form-item link">
                    <div class="key">链接</div>
                    <el-input class="value" :value="activityLink">
                        <div class="append" slot="append" @click="handleCopyLink">复制</div>
                    </el-input>
                </div>
                <div class="form-item flex-start">
                    <div class="key">二维码</div>
                    <div class="qrcode" id="qrcode"></div>
                    <el-button class="btn" icon="iconfont icon-download" @click="handleQRCodeDownload">下载二维码</el-button>
                </div>
                <div class="form-item">
                    <div class="key">海报</div>
                    <el-button type="text" size="medium" @click="routerChange(2)">海报生成</el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Core from '@/core';
const USER_TYPE = Core.Const.USER_TYPE;
import QRCode from 'qrcodejs2';

export default {
    components: {
        NoData: () => import('@/components/Empty.vue')
    },
    props: {},
    data() {
        return {
            org_id: '',
            userType: Core.Data.getUserType(),
            USER_TYPE,

            total: 0,
            currentPage: 1,
            pageSize: 10,

            tableData: [],

            qrcodeVisible: false,

            searchForm: {
                name: ''
            },
            activityId: '',
            activityLink: '',
            activityQrCodeImg: '',

            channel_ids: [],
            channelList: [],
        };
    },
    mounted() {
        this.getTableData();
        this.getChannelList();
    },
    methods: {
        pageChange(page) {
            this.currentPage = page;
            this.getTableData();
        },
        pageSizeChange(size) {
            this.pageSize = size;
            this.getTableData();
        },
        routerChange(key, item) {
            switch (key) {
                case 1: // 活动详情
                    this.$router.push({
                        path: '/operate-quotient/event-detail',
                        query: {
                            id: item.id
                        }
                    });
                    break;
                case 2: // 海报生成
                    this.$router.push({
                        path: '/operate-quotient/poster-create',
                        query: {
                            id: this.activityId,
                        }
                    });
                    break;
                case 3: // 活动生成
                    this.$router.push({
                        path: '/operate-quotient/event-edit',
                        query: {}
                    });
                    break;
            }
        },
        getTableData() {
            Core.operationApi.Evaluation.list(
                this.currentPage,
                this.pageSize
            ).then((res) => {
                console.log('getTableData res:', res);
                this.total = res.count;
                this.tableData = res.list;
            });
        },
        async getChannelList() {
            let page = 1;
            let total = 0;
            let channelList = [];
            let res = await Core.Api.OperSet.channelList(page, 20)

            total = res.count
            channelList = res.list

            while (channelList.length < total) {
                page++
                res = await Core.Api.OperSet.channelList(page, 20)
                channelList = [...channelList, ...res.list]
            }
            console.log('getChannelList res:', channelList)
            this.channelList = channelList
        },
        handleQRCodeShow(item) {
            this.qrcodeVisible = true;
            this.activityId = item.id
            this.activityLink = `http://gda.school.rebuildsoft.com/#/before?id=${item.id}&channel=`
            this.$nextTick(function () {
                this.handleQRCodeGenerate(this.activityLink);
            });
        },
        handleChannelChange() {
            this.activityLink = `http://gda.school.rebuildsoft.com/#/before?id=${this.activityId}&channel=${this.channel_ids}`;
            this.$nextTick(function () {
                this.handleQRCodeGenerate(this.activityLink);
            });
        },

        handleQRCodeGenerate(link) {
            // 生成二维码
            document.getElementById('qrcode').innerHTML = ''; //清除上次二维码的数据
            let qrcode = new QRCode('qrcode', {
                width: 125,
                height: 125, // 高度
                text: link // 二维码内容
            });
            let qrcodeImgDom = qrcode._el.children[0];
            this.activityQrCodeImg = qrcodeImgDom
                .toDataURL('image/png')
                .replace('image/png', 'image/octet-stream');
            return qrcode;
        },
        handleQRCodeClose() {
            this.qrcodeVisible = false;
            this.channel_ids = ''
        },

        handleCopyLink() {
            this.$copyText(this.activityLink)
                .then((res) => {
                    this.$message.success('内容已复制到剪切板');
                })
                .catch((err) => {
                    this.$message.warning('复制失败');
                });
        },
        handleQRCodeDownload() {
            console.log('下载图片');
            let a = document.createElement('a');
            a.download = `${this.activityLink}.png`;
            a.href = this.activityQrCodeImg;
            a.click();
        },
    }
};
</script>
<style lang="scss" scoped>
.container {
    @import '@/common/styles/table.scss';
}

.activity-share-dialog {
    .form-item {
        display: flex;
        align-items: center;
        width: 100%;
        box-sizing: border-box;
        padding: 0 10px;
        &.flex-start {
            align-items: flex-start;
        }
        &.link {
            // margin-bottom: 40px;
            .value {
                display: flex;
                justify-content: space-between;
            }
        }

        + .form-item {
            margin-top: 16px;
        }

        .key {
            width: 72px;
        }
        .value {
            width: calc(100% - 72px);
        }
        .btn {
            margin-left: 20px;
        }

        .qrcode {
            width: 125px;
            height: 125px;
            padding: 6px;
            border: 1px solid #e7ecf1;
            background: #f8fafc;
            img {
                height: 100%;
            }
        }
    }
}
</style>

<style lang="scss">
.activity-share-dialog {
    .form-item {
        &.link {
            .el-input-group__append {
                background-color: #1D517E;
                border-color: #1D517E;
                color: #fff;
                padding: 0;
                min-width: 65px;
                cursor: pointer;
                .append {
                    @include flex(row, center, center);
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}
</style>